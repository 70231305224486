export default [
  {
    path: '/employee/employee-list',
    name: 'employee-list',
    component: () => import('@/views/employee/employee-list/EmployeeList.vue'),
    meta: {
      Permission: 'employees_page_menu',
    },
  },

  {
    path: '/employee/employee-edit/:id',
    name: 'employee-edit',
    component: () => import('@/views/employee/employee-add/EmployeeEdit.vue'),
    meta: {
      Permission: 'employees_page_menu',
    },
  },

  {
    path: '/employee/employee-view/:id',
    name: 'employee-view',
    component: () => import('@/views/employee/employee-view/EmployeeView.vue'),
    meta: {
      Permission: 'employees_page_menu',
    },
  },

  {
    path: '/employee/employee-report',
    name: 'employee-report',
    component: () => import('@/views/employee/employee-report/EmployeeReport.vue'),
    meta: {
      Permission: 'employee_report_menu',
    },
  },

  {
    path: '/employee/employee-add',
    name: 'employee-add',
    component: () => import('@/views/employee/employee-add/EmployeeAdd.vue'),
    meta: {
      Permission: 'employees_add_btn',
    },
  },

  {
    path: '/employee/team-list',
    name: 'team-list',
    component: () => import('@/views/employee/team-list/TeamList.vue'),
    meta: {
      Permission: 'employees_page_menu',
    },
  },

  {
    path: '/employee/team-edit/:id',
    name: 'team-edit',
    component: () => import('@/views/employee/team-add/TeamEdit.vue'),
    meta: {
      Permission: 'employees_page_menu',
    },
  },

  {
    path: '/employee/team-view/:id',
    name: 'team-view',
    component: () => import('@/views/employee/team-view/TeamView.vue'),
    meta: {
      Permission: 'employees_page_menu',
    },
  },

  {
    path: '/employee/team-add',
    name: 'team-add',
    component: () => import('@/views/employee/team-add/TeamAdd.vue'),
    meta: {
      Permission: 'employees_add_btn',
    },
  },
];
