export default [
  {
    path: '/invoice/invoice-list',
    name: 'invoice-list',
    component: () => import('@/views/invoice/invoice-list/InvoiceList.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/invoice/invoice-add',
    name: 'invoice-add',
    component: () => import('@/views/invoice/invoice-add/InvoiceAdd.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/invoice/invoice-edit/:id',
    name: 'invoice-edit',
    component: () => import('@/views/invoice/invoice-edit/InvoiceEdit.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/invoice/invoice-preview/:id',
    name: 'invoice-preview',
    component: () => import('@/views/invoice/invoice-preview/InvoicePreview.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },
];
