export default [
  {
    path: '/client/client-list',
    name: 'client-list',
    component: () => import('@/views/client/client-list/ClientList.vue'),
    meta: {
      Permission: 'client_page_menu',
    },
  },

  {
    path: '/client/client-edit/:id',
    name: 'client-edit',
    component: () => import('@/views/client/client-add/ClientEdit.vue'),
    meta: {
      Permission: 'client_page_menu',
    },
  },

  {
    path: '/client/client-add',
    name: 'client-add',
    component: () => import('@/views/client/client-add/ClientAdd.vue'),
    meta: {
      Permission: 'client_add_btn',
    },
  },

  {
    path: '/client/client-view/:id',
    name: 'client-view',
    component: () => import('@/views/client/client-view/ClientView.vue'),
    meta: {
      Permission: 'client_page_menu',
    },
  },
];
