export default [
  {
    path: '/',
    name: 'event-list',
    component: () => import('@/views/HomePage.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },

  {
    path: '/event/event-add',
    name: 'event-add',
    component: () => import('@/views/event/EventAdd.vue'),
    meta: {
      Permission: 'event_add_btn',
    },
  },

  {
    path: '/event/event-edit/:id',
    name: 'event-edit',
    component: () => import('@/views/event/EventEdit.vue'),
    meta: {
      Permission: 'event_edit_btn',
    },
  },

  {
    path: '/event/event-view/:id',
    name: 'event-view',
    component: () => import('@/views/event/event-view/EventView.vue'),
    meta: {
      Permission: 'no_permission_required',
    },
  },
];
