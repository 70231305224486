export default [
  {
    path: '/vehicle/vehicle-list',
    name: 'vehicle-list',
    component: () => import('@/views/vehicle/vehicle-list/VehicleList.vue'),
    meta: {
      Permission: 'vehicle_page_menu',
    },
  },

  {
    path: '/vehicle/vehicle-edit/:id',
    name: 'vehicle-edit',
    component: () => import('@/views/vehicle/vehicle-add/VehicleEdit.vue'),
    meta: {
      Permission: 'vehicle_page_menu',
    },
  },

  {
    path: '/vehicle/vehicle-add',
    name: 'vehicle-add',
    component: () => import('@/views/vehicle/vehicle-add/VehicleAdd.vue'),
    meta: {
      Permission: 'vehicle_add_btn',
    },
  },

  {
    path: '/vehicle/vehicle-view/:id',
    name: 'vehicle-view',
    component: () => import('@/views/vehicle/vehicle-view/VehicleView.vue'),
    meta: {
      Permission: 'vehicle_page_menu',
    },
  },
];
